import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  fetchCountries,
  fetchCountryStates,
  fetchCountryStateCities,
  fetchAttractionFromLocation,
  fetchAttractionDetails,
  fetchAttractionReviews,
  createReview,
  fetchCities,
  fetchTestimonials,
  createTestimonial,
  fetchCorporates,
  createCorporateCompanyUser,
  verifyCorporateUserOTP,
} from "../apis/dashboardAPI";

const initialState = {
  countries: [],
};

// Async calls.
export const listCountries = createAsyncThunk(
  "dashboard/listCountries",
  async () => {
    const response = await fetchCountries();
    return response.data;
  }
);

export const listCountryStates = createAsyncThunk(
  "dashboard/listCountryStates",
  async (params) => {
    const response = await fetchCountryStates(params);
    return response.data;
  }
);

export const listCountryStateCities = createAsyncThunk(
  "dashboard/listCountryStateCities",
  async (params) => {
    const response = await fetchCountryStateCities(params);
    return response.data;
  }
);

export const listCities = createAsyncThunk(
  "dashboard/listCities",
  async (params) => {
    const response = await fetchCities();
    return response.data;
  }
);

export const listAttractionFromLocation = createAsyncThunk(
  "dashboard/listAttractionFromLocation",
  async (params) => {
    const response = await fetchAttractionFromLocation(params);
    return response.data;
  }
);

export const getAttractionDetails = createAsyncThunk(
  "dashboard/getAttractionDetails",
  async (params) => {
    const response = await fetchAttractionDetails(params);
    return response.data;
  }
);

export const listAttractionReviews = createAsyncThunk(
  "dashboard/listAttractionReviews",
  async (params) => {
    const response = await fetchAttractionReviews(params);
    return response.data;
  }
);

export const createReviewForAttraction = createAsyncThunk(
  "dashboard/createReviewForAttraction",
  async (params) => {
    const response = await createReview(params);
    return response;
  }
);

export const listTestimonials = createAsyncThunk(
  "dashboard/listTestimonials",
  async (params) => {
    const response = await fetchTestimonials(params);
    return response.data;
  }
);

export const createTestimonials = createAsyncThunk(
  "dashboard/createTestimonials",
  async (params) => {
    const response = await createTestimonial(params);
    return response.data;
  }
);

export const listCorporates = createAsyncThunk(
  "dashboard/listCorporates",
  async (params) => {
    const response = await fetchCorporates(params);
    return response.data;
  }
);

export const createCorporateUser = createAsyncThunk(
  "dashboard/createCorporateUser",
  async (params) => {
    const response = await createCorporateCompanyUser(params);
    return response.data;
  }
);

export const verifyOTP = createAsyncThunk(
  "dashboard/verifyCorporateUserOTP",
  async (params) => {
    const response = await verifyCorporateUserOTP(params);
    return response.data;
  }
);

// Account slice for configuring reducers and actions.
export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(listCountries.fulfilled, (state, action) => {
        state.countries = action.payload;
      })
      .addCase(listCountries.rejected, (state) => {
        state.countries = [];
      });
  },
});

export default dashboardSlice.reducer;
