import { useEffect, useState } from "react";
import Select from "react-select";
import "../testimonials/TestimonialComponent.scss";
import "./LandingComponent.scss";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import dalLake from "../../assets/landing-img-1.jpg";
import mughalGardens from "../../assets/landing-img-2.jpg";
import shalimar from "../../assets/landing-img-3.jpg";
import jsNormal from "../../assets/img/landing/Safari_normal.svg";
import jsSelected from "../../assets/img/landing/Safari_selected.svg";

// Banners
import banner1 from "../../assets/img/landing/banner/1_Tadoba1_banner.jpeg";
import banner2 from "../../assets/img/landing/banner/2_Kuraigad-Water2_banner.jpeg";
import banner3 from "../../assets/img/landing/banner/3_JB3_banner.jpeg";
import banner4 from "../../assets/img/landing/banner/4_Bajarang-May2019_banner.jpeg";

import itNormal from "../../assets/img/landing/International-Trip_normal.svg";
import itSelected from "../../assets/img/landing/International-Trip_selected.svg";

import dtNormal from "../../assets/img/landing/Domestic-Trip_normal.svg";
import dtSelected from "../../assets/img/landing/Domestic-Trip_selected.svg";

// Jungle safari images
import tadoaba_1 from "../../assets/img/landing/jungle-safari/1_Tadoba1_card.jpeg";
import kuraigad_2 from "../../assets/img/landing/jungle-safari/2_Kuraigad-Water2_card.jpeg";
import jb_3 from "../../assets/img/landing/jungle-safari/3_JB3_card.jpeg";
import bajarang_4 from "../../assets/img/landing/jungle-safari/4_Bajarang-May2019_card.jpeg";

//Domestic trips images
import rajastan_1 from "../../assets/img/landing/domestic-trips/01_Rajasthan_Amber Fort_Medium.jpg";
import kashmir_2 from "../../assets/img/landing/domestic-trips/02_Kashmir_Himachal Pradesh_Medium.jpg";
import sikkim_3 from "../../assets/img/landing/domestic-trips/03_North East_Sikkim_Medium.jpg";
import kerala_4 from "../../assets/img/landing/domestic-trips/04_Kerala_Alappuzha_Medium.jpg";

//Internation trips images
import dubai_1 from "../../assets/img/landing/internation-trips/01_Dubai_Medium.jpg";
import thailand_2 from "../../assets/img/landing/internation-trips/02_Thailand_Medium.jpg";
import europe_3 from "../../assets/img/landing/internation-trips/03_Europe_Medium.jpg";
import vietnam_4 from "../../assets/img/landing/internation-trips/04_Vietnam_Medium.jpg";

import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { toast } from "react-toastify";
import BlockUi from "react-block-ui";
import { createEnquirySlice } from "../../shared/redux/slices/enquirySlice";

import {
  listAttractionFromLocation,
  listCities,
  listCountries,
  listCountryStateCities,
  listCountryStates,
  listTestimonials,
  listCorporates,
  createCorporateUser,
  verifyOTP,
} from "../../shared/redux/slices/dashboardSlice";

const LandingComponentNew = (props) => {
  const slides = [
    {
      src: dalLake,
      altText: "Dal Lake",
      caption1: "Discover Amazing Places of the world",
      caption2: "Discover Amazing Places of the world",
      url: "#",
    },
    {
      src: mughalGardens,
      altText: "Mughal Gardens",
      caption1: "Discover Amazing Places of the world",
      caption2: "Discover Amazing Places of the world",
      url: "#",
    },
    {
      src: banner1,
      altText: "North India",
      caption1: "Discover Amazing Places of the world",
      caption2: "Discover Amazing Places of the world",
      url: "#",
    },
    {
      src: shalimar,
      altText: "Shalimar",
      caption1: "Discover Amazing Places of the world",
      caption2: "Discover Amazing Places of the world",
      url: "#",
    },

    {
      src: banner2,
      altText: "South India",
      caption1: "Discover Amazing Places of the world",
      caption2: "Discover Amazing Places of the world",
      url: "#",
    },
    // {
    //   src: banner3,
    //   altText: "Central India",
    //   caption1: "Discover Amazing Places of the world",
    //   caption2: "Discover Amazing Places of the world",
    //   url: "#",
    // },
    // {
    //   src: banner4,
    //   altText: "North East India",
    //   caption1: "Discover Amazing Places of the world",
    //   caption2: "Discover Amazing Places of the world",
    //   url: "#",
    // },
  ];

  // const

  // const countryOptions = [{ value: "india", label: "India" }];

  const dispatch = useDispatch();
  const history = useHistory();
  const countries = useSelector((state) => state.dashboard.countries);

  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");

  const [stateOptions, setStateOptions] = useState([]);
  const [selectedState, setSelectedState] = useState("");

  const [cityOptions, setCityOptions] = useState([]);
  const [allCityOptions, setAllCityOptions] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");

  const [attractions, setAttractions] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [corporatesList, setCorporatesList] = useState([]);

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const [loading, setLoading] = useState(false);

  const [firstTimeLoad, setFirstTimeLoad] = useState(true);
  const [optSent, setOtpSent] = useState(false);

  const trips = {
    JUNGLE_SAFARI: "jungle safari",
    DOMESTIC_TRIPS: "domestic trips",
    INTERNATIONAL_TRIPS: "international trips",
  };

  const [tripSelected, setTripSelected] = useState(trips.JUNGLE_SAFARI);

  const jungleSafariList = [
    {
      image: tadoaba_1,
      name: "North India",
      desctiption: "Ranthambore, Corbett, Pilibhit, Dudhwa",
    },
    {
      image: kuraigad_2,
      name: "South India",
      desctiption: "Bandipur, Nagarhole, Kabini",
    },
    {
      image: jb_3,
      name: "Central India",
      desctiption:
        "Kanha, Tadoba, Pench, Bandhavgarh, Panna, Umred, Satpura, Tipeshwar, Gir",
    },
    {
      image: bajarang_4,
      name: "North East India",
      desctiption: "Sundarbans, Kaziranga, Manas",
    },
  ];

  const domesticTripsList = [
    {
      image: rajastan_1,
      name: "North & Central India",
      desctiption:
        "Rajasthan, Maharashtra, Gujarat, Delhi, Madhya Pradesh, Uttar Pradesh",
    },
    {
      image: kashmir_2,
      name: "Himalayas",
      desctiption: "Kashmir, Himachal Pradesh, Uttarakhand",
    },
    {
      image: sikkim_3,
      name: "North East India",
      desctiption: "7 Sisters, Odisha, West Bengal",
    },
    {
      image: kerala_4,
      name: "South India",
      desctiption:
        "Kerala, Tamil Nadu, Karnataka, Andhra Pradesh, Pondicherry, Andaman",
    },
  ];

  const internationTripsList = [
    {
      image: dubai_1,
      name: "Middle East",
      desctiption: "Dubai, Abu Dhabi",
    },
    {
      image: thailand_2,
      name: "South East Asia",
      desctiption:
        "Thailand, Vietnam, Indonesia, Cambodia, Philippines, Singapore, Malaysia, Maldives",
    },
    {
      image: europe_3,
      name: "Europe",
      desctiption:
        "UK, France, Belgium, Netherlands Italy, Switzerland, Germany, Spain, Portugal, Greece, East Europe",
    },
    {
      image: vietnam_4,
      name: "Offbeat",
      desctiption: "Azerbaijan, Egypt, Turkey, Uzbekistan, Mauritius",
    },
  ];

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === slides.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? slides.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const options = {
    slidesToScroll: 1,
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    arrows: true,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const slider = (
    <OwlCarousel
      id="banner-home-slider"
      className="owl-carousel owl-theme"
      loop
      margin={0}
      items={1}
      nav
      autoplay
    >
      {slides.map((item) => {
        return (
          <div
            className="item slick-slider-home"
            key={item.src}
            style={{ backgroundImage: `url(${item.src})` }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  {/* <div className="banner-text-home wow fadeInUp" data-wow-duration="2s">
                          <h1 className="title">{item.caption1}</h1>
                          <p className="slider-pararp">{item.caption2}</p>
                          <div className="button-common">
                              <a href={item.url} className="btn-1">Learn More</a>
                          </div>
                      </div> */}
                  <div className="button-common carasol-floating-text">
                    <div className="">
                      We are happy to help you Plan lifetime experience.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </OwlCarousel>
  );

  const addEnquiry = async () => {
    setLoading(true);
    let params = {
      destinations: [
        {
          city: formik.values.destination?.uuid,
          no_days: parseInt(formik.values?.days || 0),
        },
      ],

      number_of_adults: parseInt(formik.values?.total_people || 0),
      phone_number: formik.values?.mobile_number,
      email: formik.values.email,
    };

    const resp = dispatch(createEnquirySlice(params));
    resp
      .unwrap()
      .then((resp) => {
        // handle result here
        if (resp) {
          toast.success(
            "Thanks for connecting with submitting the enquiry. We will get back to you soon"
          );
          setLoading(false);
          history.push("/");
        } else {
          setLoading(false);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        console.log("Enquiry created Error: ", rejectedValueOrSerializedError);
        toast.error(
          "Sorry, we are unable to submit your enquiry. Please try again later"
        );
        setLoading(false);
      });
  };

  // Formik
  const formik = useFormik({
    initialValues: {
      destination: "",
      days: "",
      total_people: "",
      email: "",
      mobile_number: "",
      isCorporateUser: false,
      corporateCompany: "",
      corporate_email: "",
      otp: "",
    },
    validationSchema: Yup.object().shape({
      destination: Yup.object().required("Please select the destination"),
      days: Yup.string().required("Please enter the Days"),
      total_people: Yup.string().required("Please enter the Total People"),
      email: Yup.string()
        .required("Please enter your Email")
        .email("Must be a valid email"),
      mobile_number: Yup.string().required("Please enter your Mobile"),
      isCorporateUser: Yup.boolean().notRequired(),
      corporateCompany: Yup.object().when("isCorporateUser", {
        is: (isCorporateUser) => isCorporateUser === true,
        then: () => Yup.object().required("Please select corporates"),
        otherwise: () => Yup.object().notRequired(),
      }),
      corporate_email: Yup.string().when("isCorporateUser", {
        is: (isCorporateUser) => isCorporateUser === true,
        then: () =>
          Yup.string()
            .required("Please enter corporate Email")
            .email("Must be a valid email"),
        otherwise: () => Yup.string().notRequired(),
      }),
      otp: Yup.string().when("isCorporateUser", {
        is: (isCorporateUser) => isCorporateUser === true,
        then: () => Yup.string().required("Please enter the OTP"),
        otherwise: () => Yup.string().notRequired(),
      }),
    }),
    onSubmit: (values) => {
      console.log("Formik values", values);
      debugger;
      addEnquiry();
    },
  });

  const countryChangeHandler = (value) => {
    console.log("Country value", value);
    setSelectedCountry(value);
    setStateOptions([]);
    setSelectedState("");
    setCityOptions([]);
    setSelectedCity("");
    getStates(value?.uuid);
    getCities(undefined, value?.uuid);
  };

  const stateChangeHandler = (value) => {
    setSelectedState(value);
    setCityOptions([]);
    setSelectedCity("");
    getCities(value?.uuid, selectedCountry.uuid);
  };

  const getCountries = async () => {
    dispatch(listCountries());
  };

  const getStates = async (country) => {
    const resp = dispatch(listCountryStates({ country_uuid: country }));
    resp
      .unwrap()
      .then((resp) => {
        // handle result here
        if (resp) {
          const states = resp;
          if (states?.length) {
            const updatedStates = states.map((state) => {
              return { label: state.name, value: state.uuid, ...state };
            });
            const mpState = updatedStates.find(
              (x) => x.name === "Madhya Pradesh"
            );
            setStateOptions(updatedStates);
            setSelectedState(mpState);
          } else {
            setStateOptions([]);
          }
        } else {
          setStateOptions([]);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
      });
  };

  const getCities = async (state, country) => {
    const resp = dispatch(
      listCountryStateCities({
        country_uuid: country,
        state_uuid: state,
      })
    );
    resp
      .unwrap()
      .then((resp) => {
        // handle result here
        if (resp) {
          const cities = resp;
          if (cities?.length) {
            const updatedCities = cities.map((city) => {
              return { label: city.name, value: city.uuid, ...city };
            });
            setCityOptions(updatedCities);
          } else {
            setCityOptions([]);
          }
        } else {
          setCityOptions([]);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
      });
  };

  const getAllCities = async () => {
    const resp = dispatch(listCities());
    resp
      .unwrap()
      .then((resp) => {
        // handle result here
        if (resp) {
          const cities = resp;
          if (cities?.length) {
            const updatedCities = cities.map((city) => {
              return { label: city.name, value: city.uuid, ...city };
            });
            setAllCityOptions(updatedCities);
          } else {
            setAllCityOptions([]);
          }
        } else {
          setAllCityOptions([]);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
      });
  };

  const getAttractionFromLocation = async () => {
    setLoading(true);
    const resp = dispatch(
      listAttractionFromLocation({
        country_uuid: selectedCountry ? selectedCountry.uuid : undefined,
        state_uuid: selectedState ? selectedState.uuid : undefined,
        city_uuid: selectedCity ? selectedCity.uuid : undefined,
      })
    );
    resp
      .unwrap()
      .then((resp) => {
        // handle result here
        if (resp) {
          setLoading(false);
          setAttractions(resp);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        setAttractions([]);
        setLoading(false);
      });
  };

  const searchNowHandler = () => {
    getAttractionFromLocation();
  };

  const getTestimonials = async () => {
    // setLoading(true)
    const resp = dispatch(listTestimonials());
    resp
      .unwrap()
      .then((resp) => {
        // handle result here
        if (resp) {
          // setLoading(false)
          setTestimonials(resp);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        // setLoading(false)
        setTestimonials([]);
      });
  };

  const getReviewBy = (testimonial) => {
    if (testimonial.first_name || testimonial.last_name) {
      return `${testimonial.first_name} ${testimonial.last_name}`;
    } else {
      return testimonial.email;
    }
  };

  const handleOtpSubmit = () => {
    if (formik.values.corporate_email) {
      addCorporateUser();
    } else {
      toast.error("Please enter the corporate email");
    }
  };

  const getCorporates = async () => {
    // setLoading(true)
    const resp = dispatch(listCorporates());
    resp
      .unwrap()
      .then((resp) => {
        // handle result here
        if (resp) {
          // setLoading(false)
          const corporateList = resp;
          if (corporateList?.length) {
            const updatedCorporates = corporateList.map((corporate) => {
              return {
                label: corporate.name,
                value: corporate.uuid,
                ...corporate,
              };
            });
            setCorporatesList(updatedCorporates);
          } else {
            setCorporatesList([]);
          }
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        // setLoading(false)
        setCorporatesList([]);
      });
  };

  const addCorporateUser = async () => {
    setLoading(true);
    let params = {
      corporate_company_id: formik.values.corporateCompany?.value,
      email: formik.values.email,
      corporate_email: formik.values.corporate_email,
    };

    const resp = dispatch(createCorporateUser(params));
    resp
      .unwrap()
      .then((resp) => {
        // handle result here
        if (resp) {
          setOtpSent(true);
        } else {
          setLoading(false);
          setOtpSent(false);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        toast.error(
          "Sorry, we are unable to send OTP for corporate email. Please try again later"
        );
        setLoading(false);
        // setOtpSent(false);
      });
  };

  const verifyUserOTP = async () => {
    setLoading(true);
    let params = {
      corporate_email: formik.values.corporate_email,
      otp: formik.values.otp,
    };

    const resp = dispatch(verifyOTP(params));
    resp
      .unwrap()
      .then((resp) => {
        // handle result here
        if (resp) {
          toast.success(
            "OTP verified successfully. You can now submit the enquiry"
          );
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(
            "Sorry, we are unable to verify the OTP. Please try again later"
          );
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        toast.error(
          "Sorry, we are unable to verify the OTP. Please try again later"
        );
        setLoading(false);
      });
  };

  const renderTrips = (list) => {
    return list.map((safari, index) => {
      return index < 4 ? (
        <div className="col-3">
          <div className="card h-100">
            <div className="card-body-custom">
              <img
                src={safari.image}
                alt={safari.name}
                className="card-image"
              />
            </div>
            <div className="p-3 fw-semibold">{safari.name}</div>
            <div className="px-3">{safari.desctiption}</div>
          </div>
        </div>
      ) : null;
    });
  };

  useEffect(() => {
    if (countries?.length) {
      const updatedCountries = countries.map((country) => {
        return { label: country.name, value: country.uuid, ...country };
      });
      //temp by default selection
      const indCountry = updatedCountries.find((x) => x.name === "India");
      setCountryOptions(updatedCountries);
      //temp by default selection
      setSelectedCountry(indCountry);
    }
  }, [countries]);

  useEffect(() => {
    if (selectedCountry && firstTimeLoad) {
      countryChangeHandler(selectedCountry);
    }
  }, [selectedCountry]);

  // temp MP load
  useEffect(() => {
    if (selectedCountry && selectedState && firstTimeLoad) {
      setFirstTimeLoad(false);
      stateChangeHandler(selectedState);
      searchNowHandler();
    }
  }, [selectedState]);

  useEffect(() => {
    // getCountries();
    getAllCities();
    getTestimonials();
    getCorporates();
    // getAttractionFromLocation();
  }, []);

  return (
    <div className="landing-component">
      <div className="row">
        <div className="col-12 p-0 position-relative">{slider}</div>
      </div>

      <section id="tour-des">
        <div className="content-box mb-0">
          <div className="container">
            <h2>
              <b>
                Your <span>Personal Trip</span> Planner
              </b>
            </h2>
            <p className="p-0">
              We customize each journey based on a personal conversation with
              you, ensuring it aligns with your preferences and comfort.
            </p>

            <div className="text-start">
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-3">
                    <div className="form-group">
                      <label for="mail-address">
                        Safari / Domestic / Global Destination{" "}
                        <span className="error-message">*</span>
                      </label>
                      <Select
                        name="destination"
                        value={formik.values.destination}
                        options={allCityOptions}
                        placeholder="Select Destination"
                        className="enquiry-select"
                        classNamePrefix="city-destination"
                        onChange={(value) => {
                          formik.setFieldValue("destination", value);
                        }}
                        onBlur={formik.handleBlur}
                      />

                      {formik.errors.destination &&
                        formik.touched.destination && (
                          <div className="error-message">
                            {formik.errors.destination}
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="col-2">
                    <div>
                      <label for="mail-address">
                        Days
                        <span className="error-message">*</span>
                      </label>
                      <input
                        name="days"
                        value={formik.values.days}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type="text"
                        className="form-control"
                        id="no-of-days"
                        placeholder="Enter Count"
                      />
                      {formik.errors.days && formik.touched.days && (
                        <div className="error-message">
                          {formik.errors.days}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-2">
                    <div>
                      <label for="mail-address">
                        Total People
                        <span className="error-message">*</span>
                      </label>
                      <input
                        type="text"
                        name="total_people"
                        value={formik.values.total_people}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        id="total-people"
                        placeholder="Enter Count"
                      />
                      {formik.errors.total_people &&
                        formik.touched.total_people && (
                          <div className="error-message">
                            {formik.errors.total_people}
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="col-3">
                    <div>
                      <label for="mail-address">
                        Email
                        <span className="error-message">*</span>
                      </label>
                      <input
                        type="text"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        id="total-people"
                        placeholder="Your email"
                      />
                      {formik.errors.email && formik.touched.email && (
                        <div className="error-message">
                          {formik.errors.email}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-2 align-content-center">
                    <div>
                      <label for="mail-address">
                        Mobile
                        <span className="error-message">*</span>
                      </label>
                      <input
                        type="text"
                        name="mobile_number"
                        value={formik.values.mobile_number}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        id="total-people"
                        placeholder="Your mobile"
                      />
                      {formik.errors.mobile_number &&
                        formik.touched.mobile_number && (
                          <div className="error-message">
                            {formik.errors.mobile_number}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-2 align-content-center mt-4">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        name="isCorporateUser"
                        type="checkbox"
                        id="inlineCheckbox1"
                        value={formik.values.isCorporateUser}
                        onChange={formik.handleChange}
                      />
                      <label className="form-check-label" for="inlineCheckbox1">
                        Corporate user?
                      </label>
                    </div>
                  </div>

                  {formik.values.isCorporateUser && (
                    <div className="col-2">
                      <div className="form-group">
                        <label for="mail-address">
                          Corporates
                          <span className="error-message">*</span>
                        </label>
                        <Select
                          name="corporateCompany"
                          value={formik.values.corporateCompany}
                          options={corporatesList}
                          placeholder="Select Corporates"
                          className="enquiry-select"
                          classNamePrefix="city-destination"
                          onChange={(value) => {
                            formik.setFieldValue("corporateCompany", value);
                          }}
                          onBlur={formik.handleBlur}
                        />

                        {formik.errors.corporateCompany &&
                          formik.touched.corporateCompany && (
                            <div className="error-message">
                              {formik.errors.corporateCompany}
                            </div>
                          )}
                      </div>
                    </div>
                  )}

                  {formik.values.isCorporateUser && (
                    <div className="col-2 align-content-center">
                      <div>
                        <label for="mail-address">
                          Corporate Email
                          <span className="error-message">*</span>
                        </label>
                        <input
                          type="text"
                          name="corporate_email"
                          value={formik.values.corporate_email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="form-control"
                          id="total-people"
                          placeholder="Your corporate email"
                        />
                        {formik.errors.corporate_email &&
                          formik.touched.corporate_email && (
                            <div className="error-message">
                              {formik.errors.corporate_email}
                            </div>
                          )}
                      </div>
                    </div>
                  )}

                  {formik.values.isCorporateUser && optSent && (
                    <div className="col-2 align-content-center">
                      <div>
                        <label for="mail-address">
                          OTP
                          <span className="error-message">*</span>
                        </label>
                        <input
                          type="text"
                          name="otp"
                          value={formik.values.otp}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="form-control"
                          id="total-people"
                          placeholder="Your OTP"
                        />
                        {formik.errors.otp && formik.touched.otp && (
                          <div className="error-message">
                            {formik.errors.otp}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {formik.values.isCorporateUser && (
                    <div className="col-3 align-content-center">
                      <div className="flex flex-direction-row">
                        <button
                          className="btn btn-2 mt-3"
                          type="button"
                          onClick={() =>
                            optSent ? verifyUserOTP() : handleOtpSubmit()
                          }
                          disabled={
                            formik.values.corporate_email ? false : true
                          }
                        >
                          {optSent ? `Verify OTP` : `Send OTP`}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="row mt-3">
                  <div className="col-2 align-content-center">
                    <button className="btn btn-2 mt-4" type="submit">
                      Send Trip Request
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <section id="domestic-trips">
        <div className="content-box text-start py-5">
          <div className="container">
            <h3>Some of Our Amazing Domestic Trips</h3>
            <div className="row justify-content-center mt-3">
              <div className="col-2">
                <img
                  src={
                    tripSelected === trips.JUNGLE_SAFARI ? jsSelected : jsNormal
                  }
                  alt="Jungle Safari"
                  onClick={() => setTripSelected(trips.JUNGLE_SAFARI)}
                  className="cursor-pointer"
                />
              </div>
              <div className="col-2">
                <img
                  src={
                    tripSelected === trips.DOMESTIC_TRIPS
                      ? dtSelected
                      : dtNormal
                  }
                  alt="Domestic Trips"
                  onClick={() => setTripSelected(trips.DOMESTIC_TRIPS)}
                  className="cursor-pointer"
                />
              </div>
              <div className="col-2">
                <img
                  src={
                    tripSelected === trips.INTERNATIONAL_TRIPS
                      ? itSelected
                      : itNormal
                  }
                  className="cursor-pointer"
                  alt="Internaltion Trips"
                  onClick={() => setTripSelected(trips.INTERNATIONAL_TRIPS)}
                />
              </div>
            </div>
            <div className="row mt-4">
              {tripSelected === trips.JUNGLE_SAFARI &&
                renderTrips(jungleSafariList)}
              {tripSelected === trips.DOMESTIC_TRIPS &&
                renderTrips(domesticTripsList)}
              {tripSelected === trips.INTERNATIONAL_TRIPS &&
                renderTrips(internationTripsList)}
            </div>
          </div>
        </div>
      </section>

      <section id="testimonials">
        <div className="content-box text-start my-5">
          <div className="container">
            <h3>TripMitra Reviews</h3>
            <div className="row">
              <div className="row mt-3">
                {testimonials.map((testimonial, index) => {
                  return index < 4 ? (
                    <div className="col-3 video-container">
                      <div className="card">
                        <div className="card-body-custom">
                          <video
                            key={index}
                            className="video-style"
                            src={testimonial.video_s3_url}
                            muted
                            controls
                          ></video>
                        </div>
                        <div className="p-3 fw-semibold">{`${getReviewBy(
                          testimonial
                        )}`}</div>
                      </div>
                    </div>
                  ) : null;
                })}
              </div>
            </div>
            <div className="text-center mt-4">
              <button
                className="btn btn-2"
                onClick={() => history.push("/testimonials")}
              >
                View All Reviews
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* <section id="summery">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="all-space-to wow zoomIn" data-wow-duration="1.5s">
                <div className="summery-cover">
                  <ul className="all-spance text-white">
                    <li>
                      <p>Cost effective market rates for your travel needs</p>
                    </li>
                    <li>
                      <p>
                        Build your own custom itinerary to visit the places, you
                        wished to explore
                      </p>
                    </li>
                    <li>
                      <p>
                        Create lifelong memory of a truly personalized travel
                        experience
                      </p>
                    </li>
                    <li>
                      <p>Personalized 24 hours on tour assistance</p>
                    </li>
                    <li>
                      <p>Complimentary 1 night stay on your first booking</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
};
export default LandingComponentNew;
