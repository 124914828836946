import { axiosPublic, axiosServerPublicWithPort } from "../../ApiHandler";

/**
 * Provides the list of all datastore stats.
 * @returns -- list of all datastore stats.
 */
export const fetchCountries = () => {
  return axiosPublic.get(`/api/v1/trip-mitra/countries/`);
};

/**
 * Provides the list of all issues stats.
 * @returns -- list of all issues stats.
 */
export const fetchCountryStates = (params) => {
  return axiosPublic.get(`/api/v1/trip-mitra/states/${params.country_uuid}`);
};

/**
 * Provides the list of all assets stats.
 * @returns -- list of all assets stats.
 */
export const fetchCountryStateCities = (params) => {
  return axiosPublic.get(`/api/v1/trip-mitra/cities/${params.state_uuid}`);
};

/**
 * Provides the list of all assets stats.
 * @returns -- list of all assets stats.
 */
export const fetchCities = () => {
  return axiosPublic.get(`/api/v1/trip-mitra/cities/`);
};

export const fetchAttractionFromLocation = (params) => {
  return axiosPublic.post(`/api/v1/trip-mitra/attractions/locations/`, params);
};

export const fetchAttractionDetails = (params) => {
  return axiosPublic.get(`/api/v1/trip-mitra/attractions/${params.id}`);
};

export const fetchAttractionReviews = (params) => {
  return axiosPublic.post(`/api/v1/reviews`, params);
};

export const createReview = (params) => {
  // return axiosPublic.post(`/api/v1/create_reviews`, params);

  axiosPublic({
    method: "post",
    url: `/api/v1/create_reviews`,
    data: params,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

/**
 * Provides the list of testimonials.
 * @returns -- list of all testimonials.
 */
export const fetchTestimonials = (params) => {
  return axiosPublic.get(`/api/v1/trip-mitra/testimonial/`);
};

/**
 * Provides the list of corporates.
 * @returns -- list of all corporates.
 */
export const fetchCorporates = (params) => {
  return axiosPublic.get(`/api/v1/trip-mitra/corporate-company/`);
};

export const createCorporateCompanyUser = (params) => {
  return axiosPublic.post(`/api/v1/trip-mitra/user/corporate/`, params);
};

export const verifyCorporateUserOTP = (params) => {
  return axiosPublic.post(
    `/api/v1/trip-mitra//user/corporate/validate-otp/`,
    params
  );
};

export const createTestimonial = (params) => {
  return axiosPublic({
    method: "post",
    url: `/api/v1/trip-mitra/testimonial/`,
    data: params,
    // headers: { 'Content-Type': 'multipart/form-data' },
    headers: {
      Accept: "application/json, text/plain, /",
      "Content-Type": "multipart/form-data",
    },
  });
};
